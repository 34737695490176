.sc-account {
    &-row {
        #column-left,
        #column-right {
            display: block !important;
        }

        #column-right {
            @include media-breakpoint-down(xl) {
                margin-top: 1rem;
            }
        }
    }

    &-module {
        .sc-column-categories-item {
            transition: background-color $mainTransition;

            > span {
                padding: 0.75rem 1.5rem;
                line-height: 17px;
            }

            &:hover {
                background-color: #FCFCFC;
            }

            & >:not(ul) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }

        &-icon {
            width: 40px;
            height: 40px;
            display: grid;
            place-items: center;
            background: #F2F2F5;
        }
    }

    &-login-button {
        width: 190px;
    }

    &-wishlist-button {
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 2rem;
        }
    }

    &-row {
        .form-control {
            @include media-breakpoint-up(md) {
                max-width: 340px;
            }
        }
    }

    &-table {
        td,
        th {
            white-space: nowrap;
        }
    }
}