#top {
    background: $primaryDarkColor;
    padding: 0.75rem 0;
    position: relative;
    z-index: 12201;
    height: 48px;

    @include media-breakpoint-up(md) {
        padding: 0.5rem 0;
        height: 50px;
    }

    @include media-breakpoint-down(md) {
        position: sticky;
        top: 0;
        z-index: 1020;
    }

    .sc-top-btn {
        height: 20px;
        flex: 0 0 20px;
        width: 20px;
    }

    .sc-btn-icon {
        background: #C7C9D9;
    }

    .sc-dropdown-inner {
        min-width: 240px;
    }

    .sc-dropdown-toggle {
        color: #FFFFFF;

        &:after {
            background: #ffffff;
        }
    }
}

#logo {

    @include media-breakpoint-up(xl) {
        padding-right: 3.5rem;
    }

    @include media-breakpoint-down(md) {
        position: absolute;
        left: 25%;
        right: 25%;
    }
}

.top-left {
    @include media-breakpoint-down(md) {
        width: 100%;
    }

    li {
        &:not(:last-child) {
            margin-right: 1rem;
        }

        a {
            color: #FFFFFF;

            &:hover {
                text-decoration: underline;
            }
        }
    }


}

.top-right {
    .sc-dropdown-toggle {
        color: #FFFFFF;

        &:after {
            background: #FFFFFF;
        }
    }

    .popup-call-trigger {
        color: #FFFFFF;
        margin-left: 0.75rem;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

.header {
    background: #FFFFFF;
    border-bottom: 1px solid #F2F4F8;
    z-index: 12100;
    position: fixed;
    box-shadow: 0px -2px 8px rgb(96 97 112 / 16%);

    @include media-breakpoint-down(md) {
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 12100;
    }

    @include media-breakpoint-between(md, xl) {
        height: 56px;
    }

    @include media-breakpoint-up(md) {
        &:not(.with-slideshow) {
            position: sticky;
            top: 0;
        }

        &.with-slideshow {
            position: relative;
            z-index: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        box-shadow: 0px 2px 30px 10px rgba(96, 97, 112, 0.04);
    }

    .sc-btn-icon-catalog {
        width: 1rem;
        height: 1rem;
    }

    &-search {
        padding: 0 1rem 0 0;
        z-index: 1;

        &-box {
            max-width: 100%;
        }

        #search {
            max-width: 100%;
        }

        .form-control {
            background: #F8F9FA;
            border: $mainBorder;
            font-size: 0.75rem;
            color: $darkTextColor;
            height: 38px;

            &::placeholder {
                font-size: 0.75rem;
                color: #8F90A6;
            }
        }

        .sc-btn-with-icon {
            position: absolute;
            z-index: 2;
            top: 0;
            bottom: 0;
            right: 0;
            background: transparent;
            border: 0;

            &:hover {
                background: transparent;

                .sc-btn-icon {
                    background: $primaryColor;
                }
            }
        }
    }

    &-buttons {

        .sc-btn-icon {
            width: 16px;
            height: 16px;
        }

        .sc-btn-icon-catalog {
            background: $primaryColor;
        }
        
        @include media-breakpoint-down(md) {
            width: 100%;

            > * {
                flex-grow: 1;
            }

            &-catalog {
                order: 0;
            }

            &-viewed {
                order: 1;
            }

            &-cart {
                order: 2;
                padding-right: 1rem !important;
                padding-left: 1rem !important;
            }

            &-compare {
                order: 3;
            }

            &-wishlist {
                order: 4;
                padding-right: 0 !important;
            }

            
        }

        .sc-btn {
            color: #555770;
            position: relative;
            height: 38px;

            &:not(:first-child) {
                padding-left: 0.5rem;
                border-left: 1px solid #F2F2F5;

                @include media-breakpoint-up(md) {
                    padding-left: 1rem;

                    &.header-buttons-account {
                        border-left: 0;
                    }
                }
            }

            &:not(:last-child) {
                padding-right: 0.5rem;

                @include media-breakpoint-up(md) {
                    padding-right: 1rem;
                }
            }

            &.header-buttons-catalog,
            &:hover {
                color: $primaryColor;
            }

            &:hover .sc-btn-icon {
                background: $primaryColor;
            }

            @media (max-width: calc($break-xxs - 1px)) {
                span.mt-1 {
                    display: none;
                }
            }
        }

        &-cart-quantity {
            display: block;
            min-width: 1rem;
            background-color: $redColor;
            font-weight: 600;
            font-size: 10px;
            height: 1rem;
            line-height: 1rem;
            border-radius: 1rem;
            position: absolute;
            top: -0.25rem;
            right: calc(50% - 1.5rem);
            color: #fff;
        }
    }
}